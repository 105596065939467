<template>
  <div class="mx-3 my-5 text-center">
    <div v-if="hasDonated" class="mb-5">
      <div class="h3 fw-normal">Thanks mate!</div>
      <div class="h1 mt-3">🙌</div>
    </div>
    <div v-else>Donation cancelled</div>
    <a
      @click="closeWindow"
      v-if="paymentRef === 'web'"
      class="btn btn-link"
    >Close window</a>
  </div>
</template>

<script>
export default {
  name: 'Donation',
  computed: {
    paymentRef() {
      const { ref } = this.$route.query;
      return !ref || ref === '' ? 'web' : ref;
    },
    hasDonated() {
      return this.$route.query.status === '1';
    },
  },
  methods: {
    closeWindow() {
      window.close();
    },
  },
};
</script>

<style lang="scss">
#app > nav {
  display: none;
  pointer-events: none;
}
</style>
